.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(7, 46, 37);
    width: 70%;
    margin: 100px auto;
    padding: 30px;
    border-radius: 20px;
}
.add-btn img{
  height: 70px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.form input[type='text']{
  padding: 15px;
  width: 100%;
  border: 2px solid rgb(61, 82, 13);
  background-color: black;
  color: rgb(240, 241, 202);
  font-size: x-large;
  font-weight: bold;
  border-radius: 20px;
}

.App h1{
  color: rgb(207, 235, 132);
}

.form{
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 80%;
}

.update-form{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: 20px 0px 20px 145px;
}


.update-new-input[type='text']{
  padding: 10px;
  width: 100vh;
  border: 2px solid rgb(61, 82, 13);
  background-color: rgb(0, 0, 0);
  border-radius: 15px;
  font-size: large;
  font-weight: bold;
  color: rgb(240, 241, 202);
}

.update-new-btn img{
  width: 40px;
  height: 40px;
  margin-left: 30px;
}

.todo-list-items{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.todo-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item-content{
  width: 100vh;
  font-size: x-large;
  font-weight: bold;
  color: beige;
 margin-left: 40px;
 
}


.delete-item img{
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 20px 20px 20px 10px;

}

.update-item img{
  width: 40px;
  height: 40px;
  margin: 20px 50px 20px 10px;
  cursor: pointer;

}
.Item{
  display: flex;
  background-color: rgb(16, 16, 14);
  width: 70%;
  margin: 20px 20px 20px 105px;
  border-radius: 40px;
}
.todo-item button{
  background-color:transparent;
  cursor: pointer;
  border: none;
  
}